.galerie {
    background-color: black;
}
.big-video iframe {
    width: 100%;
    height: 600px;
}
.small-videos {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}
.small-video {
    max-width: 30%;
    display: inline-block;
    margin: 5px 5px;
}
.small-video img {
    width: 350px;
    height: 350px;
    object-fit: cover;
}
.text-video {
    text-align: center;
    background-color: white;
    padding: 20px;
    height: 50px;
}
.date {
    float: left;
}
.lieu {
    float: right;
}
.bandeaux {
    width: 100%;
    height: 200px;
    display: flex;
    padding-top: 50px;
}
.bandeaux-block {
    margin-top: 40px;
   /*width: 50%;
   */
   /*height: 100%;
   */
    display: inline-block;
    margin: 10px auto;
}
.button {
    margin: 0 auto;
    width: 80%;
    background-color: #ffb000;
    padding: 10px 20px;
}
.subtitle-galerie {
    margin: 0 auto;
    width: 100%;
    color: white;
}
.subtitle-galerie h1 {
    margin: 0;
}
.border-top-galerie {
    width: 50px;
    height: 30px;
    border-top: 2px solid white;
    border-left: 2px solid white;
    left: -20px;
    top: 0px;
    position: relative;
}
.border-bottom-galerie {
    width: 50px;
    height: 30px;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    right: -20px;
    float: right;
    bottom: 0px;
    position: relative;
}
.photos {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: 0 0px;
}
.photos .text-block {
    width: 300px;
    height: 300px;
    line-height: 300px;
    text-align: center;
    color: white;
    font-size: 30px;
    font-family: "4L", sans-serif;
    background-color: #ffb000;
    margin: 10px 15px;
    padding: 3px;
}
.photo {
    width: 300px;
    height: 300px;
    background-color: #ffb000;
    border-color: black;
    color: white;
    vertical-align: top;
    text-align: center;
    font-size: 30px;
    font-family: "4L", sans-serif;
    position: relative;
    margin: 10px 15px;
}
.photo img {
    width: 100%;
    height: 100%;
}
.photo .text {
    z-index: 1000;
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: black;
    background-color: #ffae00;
    max-width: 300px;
    max-height: 300px;
    padding: 3px;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
    align-items: center;
    font-size: 18px;
}
.photo:hover .text {
    opacity: 1;
}

.loadButtonContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.loadButton {
    margin: 0 auto;
    background-color: #ffb000;
    padding: 10px 20px;
}
@media (max-width: 1000) {
    .small-videos {
        display: none;
   }
    .border-top-galerie {
        display: none;
   }
    .border-bottom-galerie {
        display: none;
   }
}
