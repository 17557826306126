@font-face {
  font-family: "4L";
  src: url("fonts/Captureit.ttf") format("truetype");
}

@font-face {
  font-family: "Raleway";
  src: url("fonts/Raleway-Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2 {
  font-family: "4L", sans-serif;
  text-align: center;
}
