.lastUpdate {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 20px;
}
.graph {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-around;
}